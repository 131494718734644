<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<a-button type="primary" @click="$refs.addForm._show()">添加汇报</a-button>
						</a-col>
						<a-col span="12">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="12">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getList"
										@on-clear="getList" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="6">
									<a-select v-model="param.type" style="width:100%" @change="
																				e => {
																					this.getList();
																				}
																			" placeholder="根据汇报类型进行筛选">
										<a-select-option value="">所有</a-select-option>
										<a-select-option value="周报">周报</a-select-option>
										<a-select-option value="月报">月报</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false"
			:indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="reportId" border :loading="loading"
			:data-source="data.records">
			<!-- 标题 -->
			<template slot-scope="row" slot="rowtitle">
				<a @click="$refs.detail.show(row)">{{ row.title }}</a>
			</template>
			<!--操作-->
			<template slot-scope="row" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="修改"><a-button type="link" icon="edit"
							@click="$refs.addForm._show(row)"></a-button></a-tooltip>
				</a-button-group>
				<a-button-group shape="circle" size="small">
					<a-tooltip title="删除"><a-button type="link" v-has="'worklog:del'" icon="delete"
							@click="del(row.reportId)"></a-button></a-tooltip>
				</a-button-group>
			</template>
		</a-table>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
					@showSizeChange="setLimit" :current="data.current" />
			</a-col>
		</a-row>
		<add-form ref="addForm" @success="doRefresh"></add-form>
		<comment-list ref="comment"></comment-list>
		<detail ref="detail"></detail>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import addForm from './components/addForm.vue';
import detail from './components/detail.vue';
import commentList from './components/commentList.vue';
export default {
	components: { addForm, detail, commentList },
	data() {
		return {
			data: {},
			loading: false,
			organList: apiUtil.getOrganList(),
			pageSizeOptions: ['10', '20', '30', '40', '50'],
			pageSize: 10,
			total: '',
			param: {
				current: 1,
				limit: 20,
				keyword: '',
				type: ''
			},
			columns: [
				{ title: '标题', scopedSlots: { customRender: 'rowtitle' }, width: 100, ellipsis: true },
				{ title: '汇报人', dataIndex: 'staffName', width: 100, ellipsis: true },
				{ title: '汇报时间', dataIndex: 'createTime', width: 100, ellipsis: true },
				{ title: '周报/月报', dataIndex: 'type', width: 160, ellipsis: true, align: 'center' },

				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
			]
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
		...apiUtil,
		// 获取车辆数据
		getList() {
			this.loading = true;
			this.http
				.get('/platform/work-report/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.total = data.total;
					this.param.current = data.current;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 删除
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/work-report/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getList();
							// console.log(1111);
						});
					}
				});
			});
		},
		// 筛选条件变化重新获取数据
		doRefresh() {
			this.param.current = 1;
			this.getList();
		},

		// 筛选单位
		filterOrgan(e) {
			this.param.organId = e;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		}
	}
};
</script>

<style></style>
