<template>
	<a-modal v-model="show" title="汇报信息" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="8" v-if="getOrganId() == 0">
					<a-form-model-item label="所属单位" label-position="top" prop="organId">
						<a-select v-model="formData.organId" style="width:100%" placeholder="请选择单位">
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{ organ.name
							}}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="标题" label-position="top" prop="content">
						<a-input v-model="formData.title"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="汇报接收人" label-position="top">
						<select-staff :names="formData.receiveNames" :value="formData.receiveIds" multiple
													@input="e => (formData.receiveIds = e)"></select-staff>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="选择汇报类型" label-position="top">
						<a-select v-model="formData.type">
							<a-select-option value="周报">周报</a-select-option>
							<a-select-option value="月报">月报</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-form-model-item :label="formData.type == '周报' ? '意见建议' : '进修健康状况'" label-position="top">
				<a-textarea v-model="formData.reportFeedback"></a-textarea>
			</a-form-model-item>
			<a-form-model-item :label="formData.type == '周报' ? '重要业务' : '本月成绩'" label-position="top">
				<a-textarea v-model="formData.reportMaster"></a-textarea>
			</a-form-model-item>
			<a-form-model-item :label="formData.type == '周报' ? '解决主要问题' : '目标情况'" label-position="top">
				<a-textarea v-model="formData.reportQuestion"></a-textarea>
			</a-form-model-item>
			<a-form-model-item :label="formData.type == '周报' ? '进度与说明' : '构思建议'" label-position="top">
				<a-textarea v-model="formData.reportRemark"></a-textarea>
			</a-form-model-item>
		</a-form-model>
		<a-spin size="large" fix v-if="loading" />
		<a-row :gutter="15">
			<a-col>
				<editor v-model="formData.content" @input="e => (formData.content = e)" />
			</a-col>
		</a-row>
		<a-row style="margin-top:20px" :gutter="15">
			<a-col span="10">
				<attach-upload :value="formData.attachment" @input="e => (formData.attachment = e)"></attach-upload>
			</a-col>
		</a-row>
	</a-modal>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import selectStaff from '@/components/common/select-staff.vue';
import attachUpload from '@/components/common/attach-upload.vue';
import utils from '@/common/utils';
import editor from '@/components/component/editor.vue';
export default {
	components: { selectStaff, editor, attachUpload },
	mounted() {
		console.log(apiUtil.getUser());
	},
	data() {
		return {
			show: false,
			loading: false,
			organList: apiUtil.getOrganList(),
			formData: {
				organId: apiUtil.getOrganId(''),
				content: '',
				attachment: [],
				receiveNames: [],
				receiveIds: [],
				reportFeedback: '',
				reportMaster: '',
				reportQuestion: '',
				reportRemark: '',
				title: '',
				type: '周报'
			},
			formRule: {
				// content: [{ required: true, message: '请输入日志内容' }],
				// date: [{ required: true, message: '请选择日期' }]
			}
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					this.formData = JSON.parse(JSON.stringify(form));
					console.log(this.formData);
				} else {
					this.initformdata();
				}
			});
		},
		_close() {
			this.initformdata();
			this.show = false;
			this.$emit('close');
		},
		initformdata() {
			this.formData.organId = apiUtil.getOrganId('');
			this.formData.content = '';
			this.formData.attachment = [];
			this.formData.receiveIds = [];
			this.formData.reportFeedback = '';
			this.formData.reportMaster = '';
			this.formData.reportQuestion = '';
			this.formData.reportRemark = '';
			this.formData.title = '';
			this.formData.type = '周报';
		},
		doSubmit() {
			this.$refs.formRef.validate(valid => {
				console.log(this.formData);
				if (valid) {
					utils.showSpin();
					this.http.post('/platform/work-report/save', this.formData).then(ret => {
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.$emit('success');
								this.show = false;
							});
						}
					});
				}
			});
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}

.avatar-uploader>.ant-upload {
	width: 128px;
	height: 128px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}</style>
